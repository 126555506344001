@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .rotateY {
    transform: rotateY(180deg)
  }

  /* ... */
}

* {
  word-break: keep-all;
}

h2 {
  letter-spacing: -1px;
  margin-top: 8px;
  margin-bottom: 8px;
}

@media screen and (max-width: 768px) {
  h1 {
    font-size: 5vw;
  }

  h2 {
    margin: 1.5vw;
    font-size: 3vw;
  }
}

body {
  margin: 0;
  font-family: "Noto Sans KR", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}